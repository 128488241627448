import React from 'react';

import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';

import Layout from '../components/Layout';

import Gatsby from '../images/logos/gatsby.png';
import Bootstrap from '../images/logos/bootstrap.svg';
import ReactBootstrap from '../images/logos/react-bootstrap.svg';
import Typescript from '../images/logos/typescript.svg';
import Emacs from '../images/logos/emacs.svg';

export default function Impressum() {
	return (
		<Layout title="Impressum">
			<h2>Impressum</h2>
			<p>
				<b>Vorweg der Hinweis:</b>
				<br />
				Dies ist die Webseite der ehrenamtlichen BRK-Bereitschaft Bad Reichenhall. Daher ist diese nicht rund um
				die Uhr besetzt und erreichbar.
				<br />
				Falls Sie Anfragen zu den hauptamtlichen Diensten des BRK Kreisverbands Berchtesgadener Land haben (z.B.
				Pflege, Fahrdienst, Rettungsdienst und Krankentransport, Essen auf Rädern, Fördermitliedschaft, etc.),
				finden Sie den zuständigen Ansprechpartner auf der Webseite der Kreisgeschäftsstelle:{' '}
				<a href="https://www.brk-bgl.de/">https://www.brk-bgl.de/</a>
			</p>
			<h4>Angaben gemäß § 5 TMG:</h4>
			<p>
				BRK Bereitschaft Bad Reichenhall
				<br />
				Riedelstraße 18
				<br />
				83435 Bad Reichenhall
				<br />
			</p>
			<h4>Vertreten durch:</h4>
			<p>
				Bereitschaftsleiter Florian Halter
				<br />
				Telefon: +49 (0)8651 9590 – 46
				<br />
				Fax: +49 (0)8651 9590 – 50
				<br />
				E-Mail: florian.halter@brk-bad-reichenhall.de
			</p>
			<h4>Haftungsausschluss (Disclaimer)</h4>
			<p>
				<b>Haftung für Inhalte</b>
				<br />
				Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
				allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
				verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
				forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
				der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
				diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
				möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
				entfernen.
			</p>
			<p>
				<b>Haftung für Links</b>
				<br />
				Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
				Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
				verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
				verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
				Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
				Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
				zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
			</p>
			<p>
				<b>Urheberrecht</b>
				<br />
				Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
				Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
				Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
				Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
				Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
				Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
				auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
				Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
			</p>
			<h4>Datenschutzerklärung:</h4>
			<p>
				<b>Datenschutz</b>
				<br />
				Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf
				unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder eMail-Adressen) erhoben
				werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre
				ausdrückliche Zustimmung nicht an Dritte weitergegeben.
				<br />
				<br />
				Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
				Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
				nicht möglich.
				<br />
				<br />
				Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch Dritte zur
				Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit
				ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im
				Falle der unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
			</p>
			<p>
				<b>Datenschutzbeauftragter des Bayerischen Roten Kreuzes</b>
				<br />
				Herr Peter Fuchs
				<br />
				Garmischer Straße 19-21
				<br />
				81373 München
				<br />
				<br />
				Telefon: +49 (0)89 9241 1511
				<br />
				E-Mail: Fuchs@lgst.brk.de
			</p>
			<p>
				<b>SSL-Verschlüsselung</b>
				<br />
				Um Ihre übermittelten Daten bestmöglich zu schützen nutzen diese Website eine SSLVerschlüsselung. Sie
				erkennen derart verschlüsselte Verbindungen an dem Präfix https:// im Seitenlink in der Adresszeile
				Ihres Browsers. Unverschlüsselte Seite sind durch http:// gekennzeichnet. Sämtliche Daten, welche Sie an
				diese Website übermitteln – etwa bei Anfragen oder Logins – können dank SSL-Verschlüsselung nicht von
				Dritten gelesen werden.
			</p>
			<p>Impressum von e-recht24.de speziell für Körperschaft des öffentlichen Rechts erzeugt.</p>
			<p>
				<b>Erstellung und Design:</b>
				<br />
				webmaster@brk-bad-reichenhall.de
			</p>
			<p>
				<b>Icons:</b>
				<br />
				Beim Design dieser Webseite wurden Icons von <a href="https://icons8.de/">Icons 8</a> verwendet.
			</p>
			<p>
				<b>Tools and Technologies used:</b>
				<br />
				<a href="https://www.gatsbyjs.com/" target="_blank">
					<img src={Gatsby} height="24" />
				</a>
				&nbsp;
				<a href="https://getbootstrap.com/" target="_blank">
					<img src={Bootstrap} height="24" />
				</a>
				&nbsp;
				<a href="https://react-bootstrap.github.io/" target="_blank">
					<img src={ReactBootstrap} height="24" />
				</a>
				&nbsp;
				<a href="https://www.typescriptlang.org/" target="_blank">
					<img src={Typescript} height="24" />
				</a>
				&nbsp;
				<a href="https://www.gnu.org/software/emacs/" target="_blank">
					<img src={Emacs} height="24" />
				</a>
			</p>
		</Layout>
	);
}
